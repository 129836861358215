.form-submit-container {
  margin: 50px -15px 0;
  padding: 30px 15px 110px;
  background: #f1f1f1;
  /* background-image: linear-gradient(to top, white 0%, #f8f8f8 100%); */

  background-image: radial-gradient(
    farthest-corner at 50% 0,
    #f5f5f5 0%,
    white 70%
  );
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}
