.onlinity {
  margin-right: 8px;
  margin-left: 8px;
}

.interval-container {
  position: relative;

  overflow: hidden;

  height: 13px;
  margin-top: 30px;
}
.interval-container:after {
  font-size: 1px;

  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
  left: 0;

  height: 1px;

  content: ' ';

  background-color: rgba(0, 0, 0, 0.14);
}
.interval-container .now {
  position: absolute;
  bottom: 0;

  width: 1px;
  height: 13px;
  margin-left: -1px;

  background-color: rgba(0, 0, 0, 0.3);
}

.interval {
  position: absolute;
  z-index: 1;
  bottom: 0;

  height: 5px;

  background-color: #5cb85c;
}

.hours {
  position: relative;
}

/* hour-width:   4.166667%; */
/* $tick-offset: 2.083334% */

.hours-hour,
.last-tick {
  font-size: 10px;

  position: relative;
  right: 2.083334%;

  float: left;

  width: 4.166667%;
  padding-top: 4px;
  padding-left: 1px;

  text-align: center;
}
.hours-hour:after,
.last-tick:after {
  font-size: 1px;

  position: absolute;
  top: 0;
  left: 50%;

  width: 1px;
  height: 3px;

  content: ' ';

  background-color: #dcdcdc;
}

.last-tick {
  position: absolute;
  right: -2.083334%;

  margin-right: 1px;
  padding-left: 0;
}
