html,
body,
#root {
  height: 100%;
}

.tab-nums {
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
}

.table-hover tbody {
  cursor: pointer;
}

.modal-body .table thead th {
  border-top: none;
}
