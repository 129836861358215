.rdtPicker {
  min-width: 250px;
}

.rdtPicker table {
  width: 100%;
  text-align: center;
}

.rdtPicker th,
.rdtPicker td {
  border-radius: 4px;
}

.rdtSwitch,
.rdtPrev,
.rdtNext,
.rdtDay {
  height: 2rem;
}

.rdtMonth,
.rdtYear {
  height: 3rem;
}

.rdtYear,
.rdtMonth,
.rdtDay,
.rdtSwitch,
.rdtPrev,
.rdtNext {
  cursor: pointer;
}

.rdtYear:hover,
.rdtMonth:hover,
.rdtDay:hover,
.rdtSwitch:hover,
.rdtPrev:hover,
.rdtNext:hover {
  background-color: #eeeeee;
}

.rdtPicker .dow {
  cursor: default;
}

.rdtOld,
.rdtNew {
  color: #999;
}

.rdtDayDisabled {
  color: #999;
  cursor: default;
}

.rdtDayDisabled:hover {
  background-color: transparent;
}

.rdtToday {
  background-color: #ffdb99;
  border-color: #ffb733;
  color: #000;
}

.rdtToday:hover {
  background-color: #ffc966;
  border-color: #f59e00;
}

.rdtActive {
  background-color: #286090;
  border-color: #204d74;
  color: #fff;
}

.rdtActive:hover {
  background-color: #204d74;
  border-color: #122b40;
}
